@import url("https://fonts.googleapis.com/css2?family=Orelega+One&display=swap");

* {
  font-family: "Orelega One", cursive;
}

.App {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0;
  margin: 0;
  min-height: 100vh;
  width: 100%;
  background-size: cover;
}
