@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.item-card {
  @include aspect-ratio(1, 1);

  position: relative;
  background-color: black;
  overflow: hidden;
  &-content {
    top: 0;
    left: 0;
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    overflow: hidden;
    img {
      object-fit: contain;
      width: 40%;
    }
  }
  .backdrop {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    top: 0;
    left: 0;
    object-fit: fill;
    opacity: 0.3;
  }
}
